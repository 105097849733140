/*------------- RESET CSS -------------*/

*, *::before, *::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
}

img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
}

input, button, textarea, select {
    font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}

#root, #__next {
    isolation: isolate;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    text-decoration: none;
}

/*------------- SWEET ALERT 2 OVERRIDE -------------*/
.swal2-container-index {
    z-index: 9999;
}

.swal2-container .swal2-cancel {
    background-color: #1C252E;
    color: #FFF;

    &:hover {
        background-color: #454F5B;
    }
}

.swal2-container .swal2-confirm {
    border: solid 1px #1C252E;
    background-color: #fff;
    color: #1C252E;

    &:hover {
        background-color: #1C252E;
        color: #fff;
    }
}
